import React from 'react';
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import Topbar from "../components/Topbar";
import Circular from '../components/Circular';


const circular = () => {
    return (
        <Layout pageTitle="Shanti Asiatic School | Admission Form">
            <Topbar />
            <NavOne />
            <PageHeader title="Circular" />
            <Circular/>
            <Footer />
        </Layout>
    );
};

export default circular;
